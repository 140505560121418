// Usage: [...rules].sort(sortRules);
export default function sortRules<
  E extends {
    timestamp: number;
  },
  T extends {
    event: E;
  },
>(a: T, b: T): number {
  return b.event.timestamp - a.event.timestamp;
}
