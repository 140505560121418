import { debounceTime, map, mapTo, startWith } from 'rxjs/operators';

import { setViewport } from './actions';
import { resize$ } from './domEvents';
import { getViewport } from './screen';

export default function createViewportChanges() {
  return resize$.pipe(
    mapTo(true),
    debounceTime(10),
    startWith(true),
    map(getViewport),
    map(viewport => setViewport({ viewport })),
  );
}
