import type { ActivationRule, GeoData, GeoTargetRule, GeoTargetWithCity } from '../types';

const isShowRule = (rule: GeoTargetRule | null) => Boolean(rule && rule.visibility === 'show');
const isRegionCode = (codeString: string) => /^([A-Z]|\d){1,3}$/.test(codeString);

const isMatch = (rule: GeoTargetRule, geoData: GeoData, properties: (keyof GeoData)[]) =>
  properties.every(
    property =>
      typeof rule[property as keyof GeoTargetRule] === 'string' &&
      typeof geoData[property] === 'string' &&
      rule[property as keyof GeoTargetRule]?.toLowerCase() ===
        (geoData[property] as string).toLowerCase(),
  );

const isCityMatch = (rule: GeoTargetWithCity, geoData: GeoData) => {
  if (!isMatch(rule, geoData, ['countryCode'])) {
    return false;
  }

  const { latitude, longitude } = geoData;

  // Fastly sometimes returns a sub-city location (eg: borough), so we need to check coords/bounds
  if (rule.bounds && latitude && longitude) {
    const latMatch = latitude <= rule.bounds.north && latitude >= rule.bounds.south;
    const lngMatch = longitude <= rule.bounds.east && longitude >= rule.bounds.west;

    if (latMatch && lngMatch) {
      return true;
    }
  }

  let ruleCityRegex;
  let dataCityRegex;

  try {
    ruleCityRegex = new RegExp(`^${rule.city}\\b`, 'i');
    dataCityRegex = new RegExp(`^${geoData.city}\\b`, 'i');
  } catch (err: unknown) {
    return false; // One of the values produced an invalid regex, eg: Fastly gave '?' as the city
  }

  const gotRegionCodes = rule.regionCode && isRegionCode(geoData.regionCode);
  // The UI may generate rules without region codes if it can't resolve them
  const regionMatch = !gotRegionCodes || isMatch(rule, geoData, ['regionCode']);

  const nameMatch = ruleCityRegex.test(geoData.city) || dataCityRegex.test(rule.city);
  // Let "frankfurt am main" match "Frankfurt"

  return regionMatch && nameMatch;
};

const matchesVisitorLocation = (geoData: GeoData) => (rule: GeoTargetRule) => {
  if ('city' in rule) {
    return isCityMatch(rule, geoData);
  }

  if ('regionCode' in rule) {
    return isMatch(rule, geoData, ['regionCode', 'countryCode']);
  }

  if ('countryCode' in rule) {
    return isMatch(rule, geoData, ['countryCode']);
  }

  if ('continentCode' in rule) {
    return isMatch(rule, geoData, ['continentCode']);
  }

  return false;
};

const getSpecificity = (rule: GeoTargetRule) => {
  if ('city' in rule) {
    return 4;
  }
  if ('regionCode' in rule) {
    return 3;
  }
  if ('countryCode' in rule) {
    return 2;
  }
  if ('continentCode' in rule) {
    return 1;
  }
  return 0;
};

export default function geoFilter(
  geoData: GeoData | null | undefined,
  activationRule: ActivationRule,
) {
  const { enabled, rules } = activationRule.geoTargets;

  if (!geoData || !enabled || rules.length === 0) {
    return true;
  }

  const showRules = rules.filter(isShowRule);
  const matchingRules = rules.filter(matchesVisitorLocation(geoData));

  // If there are no show rules, we add an implicit 'show everywhere' rule, with specificity 0.
  if (showRules.length === 0) {
    matchingRules.push({ visibility: 'show', continentCode: '', displayName: '' });
  }

  const ruleWithMaxSpecificity = matchingRules.reduce(
    (currentMax: GeoTargetRule | null, rule) =>
      !currentMax || getSpecificity(rule) > getSpecificity(currentMax) ? rule : currentMax,
    null,
  );

  return isShowRule(ruleWithMaxSpecificity);
}
