import { merge, of } from 'rxjs';
import { mergeMap, scan, tap } from 'rxjs/operators';

import { upgradeActivationRule } from '@unbounce/emb-core';
import { Action, setActivationRules, setActivationRulesPreview, setVisitorId } from './actions';
import { browserSupportsLive, browserSupportsPreview } from './browserSupport';
import createDispatcher from './createDispatcher';
import createErrorReporter from './createErrorReporter';
import createInitialState from './createInitialState';
import createKeyboardEscape from './createKeyboardEscape';
import createLocationHrefChanges from './createLocationHrefChanges';
import { createScrollPositionChanges } from './createScrollPositionChanges';
import createSubscriber from './createSubscriber';
import createTrigger from './createTrigger';
import createViewportChanges from './createViewportChanges';
import handleSideEffects from './handleSideEffects';
import createLogger from './logger';
import onDocumentReady from './onDocumentReady';
import reducer from './reducer';
import scriptVersion from './scriptVersion';
import singleExecutable from './singleExecutable';
import { getVisitorId } from './visitorId';
import { getVisitorData } from './visitorTracking';

import {
  ActivationRule,
  AnyActivationRule,
  Env,
  GeoData,
  OriginServerInputs,
  PreviewInputs,
  State,
} from './types';

/**
 * We're deliberately downgrading rules to version 17. See note in Readme under
 * Consuming new activation rule schema versions.
 */
const ACTIVATION_RULE_VERSION = 17;
const UPGRADER = upgradeActivationRule(ACTIVATION_RULE_VERSION) as (
  rule: AnyActivationRule,
) => ActivationRule;

declare global {
  interface Window {
    ube: {
      init: (inputs: OriginServerInputs) => void;
      preview: (inputs: PreviewInputs) => void;
      __embeddables?: any[];
    };
  }
}

function processRules(
  activationRules: ActivationRule[],
  stateInputs: {
    environment: Env;
    geoData?: GeoData | null;
    previewMode: boolean;
    ubCode: string;
  },
  setRulesAction: Action,
  logger: ReturnType<typeof createLogger>,
  errorReporter: ReturnType<typeof createErrorReporter>,
): void {
  const initialState = createInitialState(stateInputs);
  const { dispatch, dispatchedAction$ } = createDispatcher();
  const subscriber = createSubscriber(handleSideEffects(dispatch), errorReporter);

  merge(
    dispatchedAction$,
    createViewportChanges(),
    createScrollPositionChanges(),
    createLocationHrefChanges(),
    createKeyboardEscape(),
    of(setVisitorId({ visitorId: getVisitorId(window.localStorage) })),
    of(setRulesAction),
    of(...activationRules).pipe(mergeMap(createTrigger(initialState))),
  )
    .pipe(
      scan<Action, { action?: Action; prev?: State; next: State }>(
        (acc, action) => ({
          action,
          prev: acc.next,
          next: reducer(acc.next, action),
        }),
        { next: initialState },
      ),
      tap(logger.logAction),
    )
    .subscribe(subscriber);
}

export function init(inputs: OriginServerInputs): void {
  const { environment, geoData, ubCode, matchingRules } = inputs;
  const logger = createLogger(environment, window.location.search);

  logger.log(`Unbounce Universal Script ${scriptVersion} (${environment})`);

  if (!browserSupportsLive()) {
    logger.log('Browser not supported. Aborting...');
    return;
  }

  const errorReporter = createErrorReporter(
    environment,
    ubCode,
    matchingRules,
    window.location.search,
  );

  onDocumentReady(() => {
    try {
      const activationRules = matchingRules.map(UPGRADER);

      const action = setActivationRules({
        ruleData: activationRules.map(activationRule => ({
          activationRule,
          randomSeed: Math.random(),
          visitorData: getVisitorData(window.localStorage, activationRule.embUuid),
        })),
      });

      const stateInputs = { environment, geoData, previewMode: false, ubCode } as const;

      processRules(activationRules, stateInputs, action, logger, errorReporter);
    } catch (error: unknown) {
      errorReporter(error as Error);
    }
  });
}

export function preview({ environment, ruleSrcPairs }: PreviewInputs): void {
  const logger = createLogger(environment, window.location.search);

  logger.log(`Unbounce Universal Script ${scriptVersion} (${environment} - preview)`);

  if (!browserSupportsPreview()) {
    logger.log('Browser not supported. Aborting...');
    return;
  }

  // eslint-disable-next-line no-console
  const errorReporter = console.error;
  const upgradedPairs: [ActivationRule, string][] = ruleSrcPairs.map(([rule, src]) => [
    UPGRADER(rule),
    src,
  ]);

  const stateInputs = {
    environment,
    geoData: null, // unused in preview
    previewMode: true,
    ubCode: '',
  } as const;

  const activationRules = upgradedPairs.map(p => p[0]);
  const action = setActivationRulesPreview({ ruleSrcPairs: upgradedPairs });

  processRules(activationRules, stateInputs, action, logger, errorReporter);
}

// Expose globally to the host page
window.ube = window.ube || {
  init: singleExecutable(init),
  preview,
};
