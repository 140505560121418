import type { Embeddable, Integrations } from './types';

type LogFunction = (...args: unknown[]) => void;

type IntegrationCallbacks = {
  onTrigger(emb: Embeddable, log: LogFunction): void;
  onConversion(emb: Embeddable, log: LogFunction): void;
};

type GaTracker = { get: (val: string) => string; send: (...args: unknown[]) => void };

declare global {
  interface Window {
    gtag: (...args: unknown[]) => void;
    google_tag_manager: Record<string, unknown>;
    dataLayer: unknown[];
    ga: {
      (command: () => void): void;
      getAll(): GaTracker[];
    };
  }
}

// TODO: This module currently sends events to both Universal Analytics (old) and Google Analytics 4
// (new) trackers if they are present on the host page. UA has been deprecated by Google and when it
// has been fully shut down (2023-07-01) we should remove the UA events.
// https://support.google.com/analytics/answer/11583528

const UNIVERSAL_ANALYTICS_CATEGORY = 'Unbounce Convertable';

/** Sends event to all Google Analytics 4 trackers, when installed directly */
function sendGA4Event(name: string, label: string, log: LogFunction) {
  // https://developers.google.com/tag-platform/gtagjs/reference#event
  // https://developers.google.com/analytics/devguides/collection/ga4/events

  const { gtag } = window;

  if (typeof gtag !== 'function') {
    return;
  }

  const params = { event_label: label } as const;

  gtag('event', name, params);

  log('Sent GA4 event:', '\n - name:   ', name, '\n - params: ', params);
}

/** Sends event to all Google Analytics 4 trackers, when installed via Google Tag Manager */
function sendGA4ViaGTMEvent(name: string, label: string, log: LogFunction) {
  // https://developers.google.com/tag-platform/gtagjs/routing
  // https://stackoverflow.com/q/76075126

  const { gtag, google_tag_manager: gtm, dataLayer } = window;

  if (typeof gtag === 'function' || !gtm || typeof gtm !== 'object' || !Array.isArray(dataLayer)) {
    return;
  }

  // The window.google_tag_manager API is undocumented (though apparently has been around for a
  // while), so could break in the future
  const trackerIds = Object.keys(gtm).filter(key => key.startsWith('G-'));
  if (trackerIds.length === 0) {
    return;
  }

  // The value pushed to dataLayer needs to be an arguments object
  // eslint-disable-next-line no-unused-vars
  function push(..._args: unknown[]) {
    // eslint-disable-next-line prefer-rest-params
    dataLayer.push(arguments);
  }

  const params = { event_label: label } as const;
  trackerIds.forEach(trackerId => push('event', name, { ...params, send_to: trackerId }));

  log(
    'Sent GA4 event (installed via GTM):',
    '\n - tracker IDs: ',
    trackerIds,
    '\n - name:        ',
    name,
    '\n - params:      ',
    params,
  );
}

/** Sends event to all Universal Analytics (legacy) trackers */
function sendUAEvent(action: string, label: string, isInteraction: boolean, log: LogFunction) {
  const { ga } = window;

  if (typeof ga !== 'function') {
    return;
  }

  ga(() => {
    // Get all analytics.js trackers registered on the page and send the event to each one.
    // See https://developers.google.com/analytics/devguides/collection/analyticsjs/tracker-object-reference#send

    const trackers = ga.getAll().reduce((acc, tracker) => {
      if (!acc.some(accTracker => accTracker.get('trackingId') === tracker.get('trackingId'))) {
        acc.push(tracker);
      }
      return acc;
    }, [] as GaTracker[]);

    trackers.forEach(tracker =>
      tracker.send('event', UNIVERSAL_ANALYTICS_CATEGORY, action, label, {
        nonInteraction: !isInteraction,
      }),
    );

    log(
      'Sent UA event:',
      '\n - tracker IDs:      ',
      trackers.map(tracker => tracker.get('trackingId')),
      '\n - category:      ',
      UNIVERSAL_ANALYTICS_CATEGORY,
      '\n - action:        ',
      action,
      '\n - label:         ',
      label,
      '\n - nonInteraction:',
      !isInteraction,
    );
  });
}

const nullCallbacks: IntegrationCallbacks = {
  onTrigger: () => null,
  onConversion: () => null,
};

function getGA4EventName(emb: Embeddable, action: string) {
  const type = emb.display.name === 'stickyBar' ? 'sticky_bar' : 'popup';
  return `unbounce_${type}_${action}`;
}

function getEventLabel(emb: Embeddable) {
  const { customEventLabel, appendVariant } = emb.activationRule.integrations.googleAnalytics;
  return (customEventLabel || emb.id) + (appendVariant ? ` - variant ${emb.variantLetter}` : '');
}

function isInteractionTrigger(emb: Embeddable) {
  const { name } = emb.trigger;
  return name === 'clickClass' || name === 'clickId' || name === 'clickSelector';
}

const googleAnalytics: IntegrationCallbacks = {
  onTrigger(emb: Embeddable, log: LogFunction) {
    const eventLabel = getEventLabel(emb);

    try {
      sendGA4Event(getGA4EventName(emb, 'view'), eventLabel, log);
    } catch (err: unknown) {
      log('Failed to send GA4 event:', err);
    }

    try {
      sendGA4ViaGTMEvent(getGA4EventName(emb, 'view'), eventLabel, log);
    } catch (err: unknown) {
      log('Failed to send GA4/GTM event:', err);
    }

    try {
      sendUAEvent('view', eventLabel, isInteractionTrigger(emb), log);
    } catch (err: unknown) {
      log('Failed to send UA event:', err);
    }
  },

  onConversion(emb: Embeddable, log: LogFunction) {
    const eventLabel = getEventLabel(emb);

    try {
      sendGA4Event(getGA4EventName(emb, 'conversion'), eventLabel, log);
    } catch (err: unknown) {
      log('Failed to send GA4 event:', err);
    }

    try {
      sendGA4ViaGTMEvent(getGA4EventName(emb, 'conversion'), eventLabel, log);
    } catch (err: unknown) {
      log('Failed to send GA4 via GTM event:', err);
    }

    try {
      sendUAEvent('conversion', eventLabel, true, log);
    } catch (err: unknown) {
      log('Failed to send UA event:', err);
    }
  },
};

export default (integrations: Integrations): IntegrationCallbacks =>
  integrations.googleAnalytics.enabled ? googleAnalytics : nullCallbacks;
