import { Subscriber } from 'rxjs';

type ReportError = (err?: Error | string) => void;

export default function createSubscriber(next: (arg1?: any) => void, report: ReportError) {
  const wrappedNext = (obj: any) => {
    try {
      next(obj);
    } catch (err: unknown) {
      report(err as Error);
    }
  };

  return Subscriber.create(wrappedNext, report);
}
