import { Observable, timer } from 'rxjs';
import { mapTo, startWith, switchMapTo } from 'rxjs/operators';

import { hrefChange$ } from '../domEvents';

import type { TriggerParameterValue } from '../types';

export default (delaySecsString: TriggerParameterValue): Observable<true> => {
  const delaySecs = parseInt(delaySecsString, 10);

  return hrefChange$.pipe(
    mapTo(true),
    startWith(true),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    switchMapTo(timer(delaySecs * 1000)),
    mapTo(true),
  );
};
