import type {
  ActivationRule,
  EmbeddableDisplayType,
  PageSize,
  ScrollPosition,
  Size,
  Viewport,
  VisitorData,
} from './types';

type $Action<T extends string, P> = {
  type: T;
  payload: P;
};

type ActionCreator<T extends string, P> = (payload: P) => $Action<T, P>;

type EmbIdPayload = {
  id: string;
};

const createAction =
  <T extends string, P>(type: T): ActionCreator<T, P> =>
  (payload: P) => ({ type, payload });

type CloseEmbPayload = {
  displayType: EmbeddableDisplayType;
};

export type CloseEmbAction = $Action<'CLOSE_EMB', CloseEmbPayload>;
export const CLOSE_EMB = 'CLOSE_EMB';
export const closeEmb: ActionCreator<'CLOSE_EMB', CloseEmbPayload> = createAction(CLOSE_EMB);

export type CloseEmbCompleteAction = $Action<'CLOSE_EMB_COMPLETE', EmbIdPayload>;
export const CLOSE_EMB_COMPLETE = 'CLOSE_EMB_COMPLETE';
export const closeEmbComplete: ActionCreator<'CLOSE_EMB_COMPLETE', EmbIdPayload> =
  createAction(CLOSE_EMB_COMPLETE);

type SetActivationRulesPayload = {
  ruleData: {
    activationRule: ActivationRule;
    randomSeed: number;
    visitorData: VisitorData;
  }[];
};
export type SetActivationRulesAction = $Action<'SET_ACTIVATION_RULES', SetActivationRulesPayload>;
export const SET_ACTIVATION_RULES = 'SET_ACTIVATION_RULES';
export const setActivationRules: ActionCreator<'SET_ACTIVATION_RULES', SetActivationRulesPayload> =
  createAction(SET_ACTIVATION_RULES);

type SetActivationRulesPreviewPayload = {
  ruleSrcPairs: [ActivationRule, string][];
};
export type SetActivationRulesPreviewAction = $Action<
  'SET_ACTIVATION_RULES_PREVIEW',
  SetActivationRulesPreviewPayload
>;
export const SET_ACTIVATION_RULES_PREVIEW = 'SET_ACTIVATION_RULES_PREVIEW';
export const setActivationRulesPreview: ActionCreator<
  'SET_ACTIVATION_RULES_PREVIEW',
  SetActivationRulesPreviewPayload
> = createAction(SET_ACTIVATION_RULES_PREVIEW);

type SetLocationHrefPayload = {
  locationHref: string;
};
type SetLocationHrefAction = $Action<'SET_LOCATION_HREF', SetLocationHrefPayload>;
export const SET_LOCATION_HREF = 'SET_LOCATION_HREF';
export const setLocationHref: ActionCreator<'SET_LOCATION_HREF', SetLocationHrefPayload> =
  createAction(SET_LOCATION_HREF);

type FormConfirmationPayload = {
  id: string;
  confirmationSrc: string;
  confirmationSize: {
    desktop: Size;
    mobile: Size;
  };
};
export type EmbFormConfirmationAction = $Action<'EMB_FORM_CONFIRMATION', FormConfirmationPayload>;
export const EMB_FORM_CONFIRMATION = 'EMB_FORM_CONFIRMATION';
export const embFormConfirmation: ActionCreator<'EMB_FORM_CONFIRMATION', FormConfirmationPayload> =
  createAction(EMB_FORM_CONFIRMATION);

type FormSubmitEventPayload = {
  id: string;
  isConversion: boolean;
};
export type FormSubmitEventAction = $Action<'FORM_SUBMIT_EVENT', FormSubmitEventPayload>;
export const FORM_SUBMIT_EVENT = 'FORM_SUBMIT_EVENT';
export const formSubmitEvent: ActionCreator<'FORM_SUBMIT_EVENT', FormSubmitEventPayload> =
  createAction(FORM_SUBMIT_EVENT);

type LinkClickEventPayload = {
  id: string;
  isConversion: boolean;
  linkUrl: string;
  shouldRedirect: boolean;
};
export type LinkClickEventAction = $Action<'LINK_CLICK_EVENT', LinkClickEventPayload>;
export const LINK_CLICK_EVENT = 'LINK_CLICK_EVENT';
export const linkClickEvent: ActionCreator<'LINK_CLICK_EVENT', LinkClickEventPayload> =
  createAction(LINK_CLICK_EVENT);

type LogPayload = {
  messages: unknown[];
};
export type LogAction = $Action<'LOG', LogPayload>;
export const LOG = 'LOG';
export const log: ActionCreator<'LOG', LogPayload> = createAction(LOG);

type EmbLoadedPayload = {
  id: string;
  pageSize: PageSize;
};
export type EmbLoadedAction = $Action<'EMB_LOADED', EmbLoadedPayload>;
export const EMB_LOADED = 'EMB_LOADED';
export const embLoaded: ActionCreator<'EMB_LOADED', EmbLoadedPayload> = createAction(EMB_LOADED);

export type TriggerEmbAction = $Action<'TRIGGER_EMB', EmbIdPayload>;
export const TRIGGER_EMB = 'TRIGGER_EMB';
export const triggerEmb: ActionCreator<'TRIGGER_EMB', EmbIdPayload> = createAction(TRIGGER_EMB);

type SetViewportPayload = {
  viewport: Viewport;
};
export type SetViewportAction = $Action<'SET_VIEWPORT', SetViewportPayload>;
export const SET_VIEWPORT = 'SET_VIEWPORT';
export const setViewport: ActionCreator<'SET_VIEWPORT', SetViewportPayload> =
  createAction(SET_VIEWPORT);

type SetScrollPositionPayload = {
  scrollPosition: ScrollPosition;
  fromScrollEvent: boolean;
};
export type SetScrollPositionAction = $Action<'SET_SCROLL_POSITION', SetScrollPositionPayload>;
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';
export const setScrollPosition: ActionCreator<'SET_SCROLL_POSITION', SetScrollPositionPayload> =
  createAction(SET_SCROLL_POSITION);

type SetVisitorIdPayload = {
  visitorId: string;
};
export type SetVisitorIdAction = $Action<'SET_VISITOR_ID', SetVisitorIdPayload>;
export const SET_VISITOR_ID = 'SET_VISITOR_ID';
export const setVisitorId: ActionCreator<'SET_VISITOR_ID', SetVisitorIdPayload> =
  createAction(SET_VISITOR_ID);

export type Action =
  | CloseEmbAction
  | CloseEmbCompleteAction
  | SetActivationRulesAction
  | SetLocationHrefAction
  | EmbLoadedAction
  | EmbFormConfirmationAction
  | FormSubmitEventAction
  | LinkClickEventAction
  | LogAction
  | SetViewportAction
  | SetScrollPositionAction
  | TriggerEmbAction
  | SetActivationRulesPreviewAction
  | SetVisitorIdAction;
