import type { UserApiConfiguration } from './types';
import { isObject } from './utils';

const defaults: UserApiConfiguration = {
  // Legacy hooks using activation rule ID
  shouldShowOverlay() {
    return true;
  },
  onConvertableShow: () => undefined,
  onConvertableDismiss: () => undefined,
  onConversion: () => undefined,

  // New hooks using emb UUID
  shouldShow() {
    return true;
  },
  onShow: () => undefined,
  onDismiss: () => undefined,
  onConvert: () => undefined,
};

declare global {
  interface Window {
    _ubeConfig?: UserApiConfiguration;
  }
}

const fetchGlobal = (): Partial<UserApiConfiguration> => {
  return window?._ubeConfig && isObject(window?._ubeConfig) ? window._ubeConfig : {};
};

export default (): UserApiConfiguration => ({
  ...defaults,
  ...fetchGlobal(),
});
