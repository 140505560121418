import { Component } from 'preact';
import { overlayFadeOutSpeed, stickyBarSlideOutSpeed } from '../cssVariables';
import noop from '../noop';
import IframeWrapper from './IframeWrapper';
import Overlay from './Overlay';
import StickyBar from './StickyBar';

import {
  closeEmb,
  closeEmbComplete,
  embFormConfirmation,
  embLoaded,
  formSubmitEvent,
  linkClickEvent,
} from '../actions';
import type { Dispatch } from '../createDispatcher';
import type { Embeddable, State } from '../types';

type Props = {
  dispatch: Dispatch;
  state: State;
};

const transitionOutSpeeds = {
  overlay: overlayFadeOutSpeed,
  stickyBar: stickyBarSlideOutSpeed,
} as const;

export default class App extends Component<Props> {
  handleClose(emb: Embeddable): void {
    this.props.dispatch(closeEmb({ displayType: emb.display.name }));
    global.setTimeout(
      () => this.props.dispatch(closeEmbComplete({ id: emb.id })),
      parseInt(transitionOutSpeeds[emb.display.name], 10),
    );
  }

  render() {
    const { state, dispatch } = this.props;

    const embs = state.embeddables
      .filter(emb => emb.status !== 'cancelled')
      .map(emb => {
        const { closedAt, display, id, isMobile, pageSrc, showConfirmation } = emb;

        const embPageSize = emb.pageSize[isMobile ? 'mobile' : 'desktop'];
        const embConfirmationSize0 = emb.confirmationSize[isMobile ? 'mobile' : 'desktop'];

        const embPageSizeCss = {
          height: `${embPageSize.height}px`,
          width: display.name === 'stickyBar' ? '100%' : `${embPageSize.width}px`,
        } as const;

        const embConfirmationSizeCss = {
          height: `${embConfirmationSize0.height}px`,
          width: display.name === 'stickyBar' ? '100%' : `${embConfirmationSize0.width}px`,
        } as const;

        const EmbeddableComponent = {
          overlay: Overlay,
          stickyBar: StickyBar,
        }[display.name];

        const visibleEmbOfType = state.visibleEmbIds[display.name];

        return (
          <EmbeddableComponent
            // We incorporate the emb's URL into the key so that if changes, for some reason,
            // Preact will remove and re-add the Overlay component (including its iframes) to the
            // DOM. This would cause the new URL to be preloaded and the state of the Overlay
            // component to reset.
            key={`${id}-${pageSrc}`}
            device={state.device}
            emb={emb}
            isVisible={visibleEmbOfType === id}
            onClose={() => this.handleClose(emb)}
            scrollPosition={state.scrollPosition}
            size={embPageSize}
            viewport={state.viewport}
          >
            <IframeWrapper
              // Main emb content
              isMobile={isMobile}
              isVisible={!showConfirmation}
              size={embPageSizeCss}
              hostPageUrl={state.locationHref || global.location.href}
              // We append the closedAt time to the src URL so that when the emb is closed, the
              // iframe reloads. This prevents embedded videos from continuing to play, but leaves
              // the iframe in a loaded state, ready to be triggered again.
              // eslint-disable-next-line @typescript-eslint/prefer-includes
              src={`${pageSrc}${/\?/.test(pageSrc) ? '&' : '?'}closedAt=${closedAt}`}
              onClose={() => this.handleClose(emb)}
              onFormConfirmation={(confirmationSize, confirmationSrc) =>
                dispatch(embFormConfirmation({ id, confirmationSize, confirmationSrc }))
              }
              onFormSubmit={isConversion => dispatch(formSubmitEvent({ id, isConversion }))}
              onLinkClick={(isConversion, linkUrl = '', shouldRedirect = false) =>
                dispatch(linkClickEvent({ id, isConversion, linkUrl, shouldRedirect }))
              }
              onLoad={pageSize => dispatch(embLoaded({ id, pageSize }))}
            />

            <IframeWrapper
              // Form confirmation dialog
              isMobile={isMobile}
              isVisible={showConfirmation}
              size={embConfirmationSizeCss}
              hostPageUrl={state.locationHref || global.location.href}
              src={emb.confirmationSrc}
              onClose={() => this.handleClose(emb)}
              onFormConfirmation={noop}
              onFormSubmit={noop}
              onLinkClick={(isConversion, linkUrl = '', shouldRedirect = false) =>
                dispatch(linkClickEvent({ id, isConversion, linkUrl, shouldRedirect }))
              }
              onLoad={noop}
            />
          </EmbeddableComponent>
        );
      });

    return <div>{embs}</div>;
  }
}
