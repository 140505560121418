import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { log, triggerEmb } from './actions';
import fetchUserApiConfig from './fetchUserApiConfig';
import cookieFilter from './ruleFilters/cookie';
import ruleToTrigger from './ruleToTrigger';

import type { Action } from './actions';
import type { ActivationRule, State } from './types';

export default (state: State) =>
  (rule: ActivationRule): Observable<Action> =>
    ruleToTrigger(rule, state).pipe(
      map(() => {
        let cookieString = '';

        try {
          cookieString = document.cookie;
        } catch (ex: unknown) {
          // noop
        }

        if (!cookieFilter(cookieString, rule)) {
          return log({
            messages: [rule.id, 'Not displaying due to cookie targeting rule', rule.cookieTargets],
          });
        } else if (!fetchUserApiConfig().shouldShowOverlay(rule.id)) {
          return log({
            messages: [rule.id, 'Not displaying due to _ubeConfig.shouldShowOverlay() callback'],
          });
        } else if (!fetchUserApiConfig().shouldShow(rule.embUuid)) {
          return log({
            messages: [rule.embUuid, 'Not displaying due to _ubeConfig.shouldShow() callback'],
          });
        } else {
          return triggerEmb({ id: rule.id });
        }
      }),
    );
