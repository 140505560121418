import { Component, ComponentChildren } from 'preact';
import { overlayPadding } from '../cssVariables';
import { stripExtraWhiteSpace } from '../utils';

import './Overlay.css';

import type { Device, Embeddable, ScrollPosition, Size, Viewport } from '../types';

type Props = {
  emb: Embeddable;
  device: Device;
  isVisible: boolean;
  onClose: () => void;
  scrollPosition: ScrollPosition;
  size: Size;
  viewport: Viewport;
};

const padding = parseInt(overlayPadding, 10) * 2;

export default class Overlay extends Component<Props> {
  static defaultProps: {
    children: ComponentChildren;
  };

  getScrollWrapperStyle() {
    const { device, isVisible, viewport, size, scrollPosition } = this.props;
    const { isIOS, isOldIOS } = device;

    if (
      isVisible &&
      (isOldIOS || (isIOS && size.height > 0 && size.height + padding > viewport.height))
    ) {
      // In iOS 9 and below, fixed-position elements like the scrollWrapper jump to the full height
      // of the page when an input is focused. In iOS 10, fixed positioning works as expected, other
      // than when a fixed-position element has overflow scrolling and a child input is focused. To
      // get around these quirks, we position the scrollWrapper absolutely and set the scrollWrapper
      // height such that it has no overflow scrolling. This means that instead of scrolling inside
      // the scrollWrapper, the user can scroll around the host page itself, along with the overlay.
      return {
        height: Math.max(viewport.height, size.height + padding),
        left: scrollPosition.left,
        overflow: 'visible',
        position: 'absolute',
        top: scrollPosition.top,
      };
    } else {
      return {};
    }
  }

  render() {
    const { emb, onClose, isVisible, children } = this.props;

    const containerClassName = stripExtraWhiteSpace(`
      ub-emb-overlay
      ${isVisible ? 'ub-emb-visible' : ''}
      ${emb.isMobile ? 'ub-emb-mobile' : ''}
    `);

    return (
      <div className={containerClassName}>
        <div className="ub-emb-backdrop" onClick={onClose} />

        <div
          className="ub-emb-scroll-wrapper"
          onClick={onClose}
          style={this.getScrollWrapperStyle()}
        >
          {children}
        </div>
      </div>
    );
  }
}

Overlay.defaultProps = {
  children: [],
};
