import { concat, of } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';

import { setScrollPosition } from './actions';
import { scroll$ } from './domEvents';
import { getScrollPosition } from './screen';

export function createCurrentScrollPosition() {
  return of(
    setScrollPosition({
      fromScrollEvent: false,
      scrollPosition: getScrollPosition(),
    }),
  );
}

export function createScrollPositionChanges() {
  return concat(
    createCurrentScrollPosition(),
    scroll$.pipe(
      throttleTime(200),
      map(() =>
        setScrollPosition({
          fromScrollEvent: true,
          scrollPosition: getScrollPosition(),
        }),
      ),
    ),
  );
}
