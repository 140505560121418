import type { Env, UbCode, Url, Uuid } from './types';

const envSuffixes: Partial<Record<Env, string>> = {
  development: '-integration',
  integration: '-integration',
  production: '',
};

export default function getPublishedUrl(
  ubCode: UbCode,
  embUuid: Uuid,
  variantLetter: string | null | undefined = 'a',
  environment: Env,
): Url {
  // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
  if (environment === 'development' && embUuid.indexOf('mock') === 0) {
    return `${embUuid}?variant=${variantLetter}`;
  }

  const envSuffix = envSuffixes[environment || 'integration'];

  return `//${ubCode}.pages${envSuffix}.ubembed.com/${embUuid}/${variantLetter}.html`;
}
