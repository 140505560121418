import { filter, mapTo } from 'rxjs/operators';

import { closeEmb } from './actions';
import { keyDown$ } from './domEvents';

export default function createKeyboardEscape() {
  return keyDown$.pipe(
    filter(ev => ev.key === 'Escape' || ev.keyCode === 27),
    mapTo(closeEmb({ displayType: 'overlay' })),
    // TODO: We should also be dispatching a closeEmbComplete event here after a 10ms delay. However
    // that action has a payload of the emb's ID and we do not know that here. This means thatif the
    // user presses 'esc', we don't reload the emb and it isn't eligible to be triggered again.
  );
}
